import { useDispatch, useSelector } from 'react-redux';
import './Footer.css';
import { addDialog, getDialogPendingAction, removeDialog } from '../reactlib/features/dialog';
import { useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import MultiRoute from '@cybus/helps/dist/helpers/MultiRoute';
import { RollupMenuTemplate } from '../reactlib/components/Rollup/RollupUtil';
import Rollup from '../reactlib/components/Rollup';

const getMenu: RollupMenuTemplate = ({ items }) => {
  return (
    <ul
      tabIndex={0}
      className="dropdown-content z-[1] menu p-2 shadow-lg border bg-base-100 text-base-content rounded-box w-auto"
    >
      {items.map((item, i) => (
        <li key={`menu-item-${i}`}>
          <a onClick={item.onClick} className="whitespace-nowrap">
            {item.text}
          </a>
        </li>
      ))}
    </ul>
  );
};

const dialogId = 'footer-dialog';
export default function Footer() {
  const pendingAction = useSelector(getDialogPendingAction);
  const dispatch = useDispatch();

  const close = () => dispatch(removeDialog(dialogId));

  const callFirebase = async () => {
    const FN = getFunctions();
    // const helloWorld = httpsCallable(FN, 'hello');
    // const result = await helloWorld({email: 'cybus10@gmail.com'});
    const archiveMessages = httpsCallable(FN, 'WebChatMessageArchiverCron');
    const result = await archiveMessages({email: 'cybus10@gmail.com'});
    console.log(result.data);
  };

  const showDialog = () => {
    dispatch(
      addDialog({
        id: dialogId,
        title: (
          <div>
            <img src="/bug.svg" alt="Report Bug" className="w-4 h-4 white-icon" /> Bug Report
          </div>
        ),
        message: 'Please call support to report bugs for now.',
        buttons: [{ label: 'Ok', className: 'btn-info', action: 'ok' }],
      })
    );
  };
  useEffect(() => {
    if (pendingAction?.id === dialogId) {
      if (pendingAction?.action === 'ok') {
        // no action
      }
      close();
    }
  }, [pendingAction, close]);
  return (
    <footer className="fixed left-0 bottom-0 w-full p-2 bg-neutral text-neutral-content flex flex-col flex-wrap justify-evenly text-sm no-print">
      {/* <nav className="flex flex-col">
        <header className="footer-title">Services</header>
        <a className="link link-hover">Roofing</a>
        <a className="link link-hover">Windows</a>
        <a className="link link-hover">Solar</a>
      </nav>
      <nav className="flex flex-col">
        <header className="footer-title">Company</header>
        <a className="link link-hover">About us</a>
        <a className="link link-hover">Contact</a>
        <a className="link link-hover">Jobs</a>
      </nav> */}
      {/* <header className="footer-title">Legal</header> */}
      {/* <nav className="flex flex-row justify-between">
        <button name="Terms of use" className="link link-hover" onClick={() => router.navigate('/terms')}>
          Terms of use
        </button>
        <button name="Privacy Policy" className="link link-hover" onClick={() => router.navigate('/terms')}>
          Privacy policy
        </button>
        <button name="Do Not Sell" className="link link-hover" onClick={() => router.navigate('/do-not-sell')}>
          Do Not Sell
        </button>
        <button name="Cookie Policy" className="link link-hover">
          Cookie policy
        </button>
      </nav> */}
      <Rollup menuTemplate={getMenu} menuAnchor="left" className="h-6 gap-4 py-2 resizable">
        <button data-target-name="mini" className="btn btn-neutral btn-sm mr-auto">
          &#9432;
        </button>
        <div data-target="mini" className="content whitespace-nowrap mr-auto" onClick={callFirebase}>
          &copy; Courts UT LLC - All Rights Reserved.
        </div>
        <button data-target-name="menu" className="link link-hover whitespace-nowrap">
          ...
        </button>
        <button
          data-target="menu"
          name="Terms of use"
          className="link link-hover whitespace-nowrap"
          onClick={() => MultiRoute.go(0,  '/terms')}
        >
          Terms of use
        </button>
        <button
          data-target="menu"
          name="Privacy Policy"
          className="link link-hover whitespace-nowrap"
          onClick={() => MultiRoute.go(0, '/terms')}
        >
          Privacy policy
        </button>
        <button
          data-target="menu"
          name="Do Not Sell"
          className="link link-hover whitespace-nowrap"
          onClick={() => MultiRoute.go(0, '/do-not-sell')}
        >
          Do Not Sell
        </button>
        <button data-target="menu" name="Cookie Policy" className="link link-hover whitespace-nowrap">
          Cookie policy
        </button>
        <button data-target="menu" className="link link-hover whitespace-nowrap" onClick={showDialog}>
          <img src="/bug.svg" alt="Report Bug" className="w-4 h-4 white-icon" />
        </button>
      </Rollup>
      {/* <Bar menuTemplate={getMenu} menuAnchor='left' className="h-6 gap-2 py-2 resizable w-full">
        <button data-target-name="mini" className="btn btn-secondary btn-sm mr-auto">
          X
        </button>
        <div data-target="mini" className="content mr-auto whitespace-nowrap">
          Created by <strong>Dev Only</strong> on Sept 28, 2021 10:11am
        </div>
        <button data-target-name="menu" className="btn btn-secondary btn-sm">
          ...
        </button>
        <button data-target="menu" className="btn btn-secondary btn-sm" onClick={() => alert('Copy 4')}>
          Copy 4
        </button>
        <button data-target="menu" className="btn btn-secondary btn-sm" onClick={() => alert('Delete')}>
          Delete
        </button>
        <button data-target="menu" className="btn btn-secondary btn-sm" onClick={() => alert('Print')}>
          Print
        </button>
        <button data-target-name="menu2" className="btn btn-primary btn-sm">
          ...
        </button>
        <button data-target="menu2" className="btn btn-primary btn-sm" onClick={() => alert('Save & New')}>
          Save & New
        </button>
        <button data-target="menu2" className="btn btn-primary btn-sm" onClick={() => alert('Save')}>
          Save
        </button>
        <button data-target="menu2" className="btn btn-primary btn-sm" onClick={() => alert('Save & Close')}>
          Save & Close
        </button>
      </Bar> */}
    </footer>
  );
}
