import React, { ReactNode } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}
interface ErrorBoundaryProps {
  fallback?: ReactNode;
  children?: ReactNode | ReactNode[];
}
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private static errorServiceFn: ((error: Error, errorInfo: any) => void) | undefined;
  state: ErrorBoundaryState;
  constructor(props: ErrorBoundaryProps | any) {
    super(props);
    this.state = { hasError: false, error: null } as ErrorBoundaryState;
  }

  static setErrorServiceFn(fn: (error: Error, errorInfo: any) => void) {
    this.errorServiceFn = fn;
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    ErrorBoundary.errorServiceFn?.(error, errorInfo);
  }

  render() {
    const props: ErrorBoundaryProps = this.props as ErrorBoundaryProps;
    if (this.state.hasError) {
      // fallback UI
      return props.fallback;
    }

    return props.children;
  }
}
