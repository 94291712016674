import { TSiteConfig } from "./reactlib/types/TSiteConfig";


export const SiteConfig:TSiteConfig = {
  systemUserId: '',
  company: 'Courts Utah',
  address: {street: '492 W 1200 N STE 3', city: 'Springville', state:'UT', zip: '84663'},
  phone: '8014943103',
  email: 'mark@courtsutah.com',
  website: 'courtsutah.com',
  onesignal: {
    appId: '8b3d8795-e674-487d-9b3c-64863f4aa389',
    domain: 'admin.courtsutah.com'
  },
  referrers: {
    'courtsutah.com': {id:'courtsutah.com', name:'Courts Utah', color:'bg-blue-600 text-white', logo: {src:'/logo-1-header-white-outline.svg', scale:1, width: 420, height: 60}},
    'fencebrosut.com': {id: 'fencebrosut.com', name:'Fence Bros', color: 'bg-blue-800 text-white', logo: {src:'/logo-fencebros-dark.svg', scale:0.7, width: 512, height: 140}},
  }
}
