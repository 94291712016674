// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import FBService from '@cybus/helps/dist/helpers/FBService';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where, onSnapshot, orderBy, limit } from 'firebase/firestore';
import Permissions from './helpers/Permissions';
import { AccountConfig } from './reactlib/features/account';
import { SiteConfig } from './SiteConfig';
import getDomainKey from './reactlib/helpers/getDomainKey';
import { GLOG_STATUS, GLogLevel } from '@cybus/helps/dist/helpers/GLog';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_Z52H_9vXR-Q_-0co-DLob1PoeTbDiUs",
  authDomain: "courtsut-224df.firebaseapp.com",
  projectId: "courtsut-224df",
  storageBucket: "courtsut-224df.appspot.com",
  messagingSenderId: "662027366453",
  appId: "1:662027366453:web:146457efe153f88b58417c",
  measurementId: "G-YVLJYYW2LC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
const DB = getFirestore(app);
GLOG_STATUS.FBService = process.env.NODE_ENV === 'production' ? GLogLevel.NONE : GLogLevel.LOG;
Permissions();

FBService.register({
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  onSnapshot,
  orderBy,
  limit,
  DB,
});
AccountConfig.domain = SiteConfig.website;
FBService.registerAccountId(getDomainKey(AccountConfig.domain));

export default DB;
