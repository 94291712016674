import React from 'react';
import Col from '../Col';

interface ErrorFallbackProps {
  giphyUrl?: string;
}
export default function ErrorFallback(props: ErrorFallbackProps) {
  const giphyUrl = props.giphyUrl || 'https://giphy.com/embed/bAftZ12SC0uEjLndIh';
  return (
    <Col className="w-full h-full justify-center items-center">
      <h1 className="text-2xl">Opps. That&apos;s not right.</h1>
      <div className="w-full p-4 relative">
        <iframe
          src={giphyUrl}
          width="100%"
          height="100%"
          className="absolute giphy-embed"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </Col>
  );
}
