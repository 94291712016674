import React, { lazy } from 'react';
import { CRUD, IPermGrant } from '../reactlib/features/permissions/IPerm';
import calendarColors from '../helpers/calendarColors';
import { SiteConfig } from '../SiteConfig';

const Login = lazy(() => import('./Login'));
const Dashboard = lazy(() => import('./Dashboard'));
const MessageBoard = lazy(() => import('./MessageBoard'));
const Logout = lazy(() => import('./Logout'));
const Users = lazy(() => import('./Users'));
const Profile = lazy(() => import('./Profile'));
const Leads = lazy(() => import('./Leads'));
const Lead = lazy(() => import('./Lead'));
const Customers = lazy(() => import('./Customers'));
const Customer = lazy(() => import('./Customer'));
const ApptsView = lazy(() => import('../reactlib/features/appointments/ApptsView'));
const ApptView = lazy(() => import('../reactlib/features/appointments/ApptView'));
const Terms = lazy(() => import('./Terms'));
const Privacy = lazy(() => import('./Privacy'));
const DoNotSell = lazy(() => import('./DOS'));
const V404 = lazy(() => import('../reactlib/components/V404/V404'));
const Todo = lazy(() => import('./Todo'));

export interface IRoute {
  path: string;
  label?: string;
  title?: string;
  payload?: any;
  drawer?: IPermGrant;
  role?: IPermGrant; // if it has a role. it requires them to be logged in
  element: React.ReactNode | null;
}

export const routes: IRoute[] = [
  {
    path: '/',
    title: 'Home',
    drawer: { key: 'login', value: CRUD.NONE },
    element: <Login />,
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    payload: { key: 'sellerId' },
    drawer: { key: 'login', value: CRUD.READ },
    role: { key: 'login', value: CRUD.READ, fallback: '/login' },
    element: <Dashboard />,
  },
  {
    path: '/leads',
    title: 'Leads',
    drawer: { key: 'leads', value: CRUD.READ },
    role: { key: 'leads', value: CRUD.READ, fallback: '/dashboard' },
    element: <Leads />,
  },
  {
    path: '/leads/:cid',
    title: 'Lead',
    role: { key: 'leads', value: CRUD.READ, fallback: '/dashboard' },
    element: <Lead />,
  },
  {
    path: '/customers',
    title: 'Customers',
    drawer: { key: 'customers', value: CRUD.READ },
    role: { key: 'customers', value: CRUD.READ, fallback: '/dashboard' },
    element: <Customers />,
  },
  {
    path: '/customers/:cid',
    title: 'Customer',
    role: { key: 'customers', value: CRUD.CREATE, fallback: '/customers' },
    element: <Customer />,
  },
  {
    path: '/messages',
    title: 'Messages',
    drawer: { key: 'customers', value: CRUD.READ },
    role: { key: 'customers', value: CRUD.READ, fallback: '/login' },
    element: <MessageBoard />,
  },
  {
    path: '/profile/:id',
    title: 'Profile',
    drawer: { key: 'login', value: CRUD.READ },
    role: { key: 'login', value: CRUD.UPDATE, fallback: '/dashboard' },
    element: <Profile />,
  },
  {
    path: '/users',
    title: 'Users',
    drawer: { key: 'users', value: CRUD.READ },
    role: { key: 'users', value: CRUD.READ, fallback: '/dashboard' },
    element: <Users />,
  },
  {
    path: '/users/:id',
    title: 'User',
    role: { key: 'users', value: CRUD.UPDATE, fallback: '/dashboard' },
    element: <Profile />,
  },
  {
    path: '/appts-by-me',
    title: 'Appts I Created',
    drawer: { key: 'appts', value: CRUD.READ },
    role: { key: 'appts', value: CRUD.READ, fallback: '/dashboard' },
    element: <ApptsView />,
  },
  {
    path: '/appts-for-me',
    title: 'Appts Assigned To Me',
    payload: { key: 'appointerId' },
    drawer: { key: 'appts', value: CRUD.READ },
    role: { key: 'appts', value: CRUD.READ, fallback: '/dashboard' },
    element: <ApptsView />,
  },
  {
    path: '/appointments/:aid',
    title: 'Appointment',
    payload: { key: 'sellerId' },
    role: { key: 'appts', value: CRUD.CREATE, fallback: '/appointments' },
    element: <ApptView config={SiteConfig} calendarColors={calendarColors} />,
  },
  {
    path: '/logout',
    title: 'Logout',
    drawer: { key: 'login', value: CRUD.NONE },
    element: <Logout />,
  },
  {
    path: '/todo',
    title: 'TODO',
    drawer: { key: 'dev', value: CRUD.DELETE },
    role: { key: 'dev', value: CRUD.DELETE },
    element: <Todo />,
  },
  // footer pages
  {
    path: '/terms',
    title: 'Terms of Service',
    element: <Terms />,
  },
  {
    path: '/privacy-policy',
    title: 'Privacy Policy',
    element: <Privacy />,
  },
  {
    path: '/do-not-sell',
    title: 'Do Not Sell Policy',
    element: <DoNotSell />,
  },
  {
    path:'*',
    element: <V404 />
  }
];

export const findRoute = (path: string): IRoute | undefined => {
  for (let i = 0; i < routes.length; i += 1) {
    if (routes[i].path === path) {
      return routes[i];
    }
  }
  return;
};
