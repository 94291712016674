import React, { useEffect, useRef, useState } from 'react';
import { IHash } from '@cybus/helps/dist/types/Hash';
import clickOutside from '@cybus/helps/dist/helpers/clickOutside';
import { IRollupData } from './RollupReducer';
import {
  IRollupMenuProps,
  IRollupProps,
  RollupChild,
  getRollupMenuOffset,
  rollupCreateMenu,
  rollupObserve,
  rollupPrepClones,
} from './RollupUtil';

export default function Rollup({ className = '', children, menuTemplate, menuAnchor = 'left', menuVAlign = 'top' }: IRollupProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [data, setData] = useState({} as IRollupData);
  const [selectedMenu, setSelectedMenu] = useState('none');
  const [modalWidth, setModalWidth] = useState(0);
  const menuProps: IHash<IRollupMenuProps[]> = {};
  const clones = rollupPrepClones(data, children as RollupChild[], menuProps, setSelectedMenu);
  const menu = rollupCreateMenu(data, selectedMenu, setSelectedMenu, menuProps, menuTemplate);
  const selectedMenuOffset = selectedMenu
    ? getRollupMenuOffset(ref, data, selectedMenu, menuAnchor, menuVAlign, modalWidth, ref.current as HTMLElement)
    : 0;

  // must be separate. returns it own destory.
  useEffect(() => {
    return rollupObserve(ref.current as HTMLElement, setData);
  }, []);
  // must be separate. returns it own destory.
  useEffect(() => {
    return clickOutside(menuRef.current as HTMLDivElement, () => {
      setSelectedMenu('');
    });
  }, []);
  // positioning modal needs to know modal size.
  useEffect(() => {
    if (menu && !modalWidth && menuRef.current) {
      setModalWidth((menuRef.current as HTMLElement).offsetWidth);
    } else if (!menu && modalWidth) {
      setModalWidth(0);
    }
  }, [setModalWidth, menuRef, menu, modalWidth]);
  return (
    <>
      <div ref={ref} className={`flex w-full border-box justify-end items-center relative ${className || ''}`}>
        {clones}
        {menu && (
          <div
            ref={menuRef}
            style={{
              ...selectedMenuOffset,
              position: 'absolute',
              bottom: '100%',
            }}
          >
            {menu}
          </div>
        )}
      </div>
    </>
  );
}
