import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDialog, getDialogs, setDialogAction } from '.';
import { useEffect } from 'react';

export default function Dialog() {
  const dispatch = useDispatch();
  const dialogs = useSelector(getDialogs);
  //TODO: need to make close fn always remove from the model.
  useEffect(() => {
    for (let i = 0; i < dialogs.length; i += 1) {
      const dialog = dialogs[i];
      // @ts-ignore
      document.getElementById(dialog.id).showModal();
    }
  }, [dialogs]);

  const renderDialog = (dialog: IDialog, i: number) => {
    return (
      <dialog key={`dialog-${i}`} id={dialog.id} className={`modal ${dialog.className}`}>
        <div className="modal-box">
          <h3 className="font-bold text-lg">{dialog.title}</h3>
          <p className="py-4">{dialog.message}</p>
          <div className="modal-action">
            {dialog.xBtn && (
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
              </form>
            )}
            {dialog.closeOnOutsideClick && (
              <form method="dialog" className="modal-backdrop">
                <button>close</button>
              </form>
            )}
            {dialog.okBtn && (
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn">{dialog.okBtn}</button>
              </form>
            )}
            {dialog.buttons && (
              <form method="dialog" className="flex flex-row w-full gap-4 justify-end">
                {/* if there is a button in form, it will close the modal */}
                {dialog.buttons.map((btn, i) => (
                  <button
                    key={`b-${i}`}
                    className={`btn ${btn.className || ''}`}
                    onClick={() =>
                      dispatch(setDialogAction({ id: dialog.id, action: btn.action, payload: btn.payload }))
                    }
                  >
                    {btn.label}
                  </button>
                ))}
              </form>
            )}
          </div>
        </div>
      </dialog>
    );
  };

  return <>{dialogs.map(renderDialog)}</>;
}
