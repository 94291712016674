import React from 'react';
import Col from '../Col';

const V404 = () => {
  return (
    <div className="w-screen h-screen">
      <Col className="m-auto">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1014 518"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
        >
          <path
            id="_4.0"
            d="M252.072,120.588L223.863,120.588L78.743,310.11L78.743,345.914L214.206,345.914L214.206,415.651L252.072,415.651L252.072,345.914L299.235,345.914L299.235,310.11L252.072,310.11L252.072,120.588ZM214.206,187.405L121.71,310.11L214.206,310.11L214.206,187.405Z"
          />
          <path
            id="ghost"
            d="M610.351,218.612C587.969,83.509 389.829,83.227 367.401,218.612C356.058,287.077 369.885,293.015 355.796,424.33C353.759,443.31 375.939,441.592 390.359,434.907C405.783,427.755 406.522,415.615 420.615,415.651C435.944,415.69 437.953,434.869 452.848,434.907C472.427,434.956 479.246,413.921 492.195,415.651C499.123,416.576 511.197,435.428 525.898,434.907C547.077,434.156 546.058,414.227 559,415.651C566.826,416.512 574.682,427.463 587.942,434.907C599.926,441.634 627.384,438.424 625.591,424.33C610.168,303.119 624.421,303.539 610.351,218.612ZM490.936,280.972C501.519,280.972 510.111,293.427 510.111,308.768C510.111,324.108 501.519,336.563 490.936,336.563C480.353,336.563 471.76,324.108 471.76,308.768C471.76,293.427 480.353,280.972 490.936,280.972ZM451.008,203.409C461.591,203.409 470.183,215.864 470.183,231.204C470.183,246.545 461.591,259 451.008,259C440.425,259 431.833,246.545 431.833,231.204C431.833,215.864 440.425,203.409 451.008,203.409ZM529.824,203.409C540.407,203.409 548.999,215.864 548.999,231.204C548.999,246.545 540.407,259 529.824,259C519.24,259 510.648,246.545 510.648,231.204C510.648,215.864 519.24,203.409 529.824,203.409Z"
          />
          <g id="_4.1" transform="matrix(1,0,0,1,600.179,0)">
            <path d="M252.072,120.588L223.863,120.588L78.743,310.11L78.743,345.914L214.206,345.914L214.206,415.651L252.072,415.651L252.072,345.914L299.235,345.914L299.235,310.11L252.072,310.11L252.072,120.588ZM214.206,187.405L121.71,310.11L214.206,310.11L214.206,187.405Z" />
          </g>
        </svg>
        <h1 className="text-2xl w-full text-center">Oops!</h1>
        <div className="w-full text-center">This isn&apos;t the right page. </div>
        <div className="w-full text-center">
          <a href="/" className="text-blue-600 underline">
            Go to home page
          </a>
        </div>
      </Col>
    </div>
  );
};

export default V404;
