import createReduxListFeature, { ItemState } from '@cybus/helps/dist/helpers/createReduxListFeature';
import extend from '@cybus/helps/dist/helpers/extend';
import formatDate from '@cybus/helps/dist/helpers/formatDate';
import sortBy from '@cybus/helps/dist/helpers/sortBy';
import { toTimeAgoShort } from '@cybus/helps/dist/helpers/toTimeAgo';
import { Hash, IHash } from '@cybus/helps/dist/types/Hash';
import { createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

export interface IChannel {
  id: string; // channel id;
  messages: IMessage[];
  unreadCount: IHash<number>; // the number of unread messages for each person.
  typing: IHash<number>; // dates each person is typing.
}

export interface IMessage {
  id: string;
  message: string;
  date: number;
  authorId: string;
  authorName: string;
  channel: string;
  read?: IHash<number>; // dates each person read it.
}

const getDefaultMessage = (): IChannel => {
  return {
    id: '',
    messages: [
      {
        id: '',
        message: '',
        date: Date.now(),
        authorId: '',
        authorName: '',
        channel: '',
      },
    ],
    unreadCount: {},
    typing: {},
  };
};

const sort = sortBy('date');

const feature = createReduxListFeature<IChannel>('messages', getDefaultMessage, sort);
export const messageSlice = createSlice(feature);

export const { setValue: setMessage, setList: setMessages, update: updateMessage, add: addMessage } = messageSlice.actions;

export const getMessage = (state: ToolkitStore | any) => state.messages.value as IChannel;
export const getMessages = (state: ToolkitStore | any) => state.messages.list as IChannel[];
export const getMessagesState = (state: ToolkitStore | any) => state.messages as ItemState<IChannel>;
export const getMessagesById = (state: ToolkitStore | any) => state.messages.hash as Hash<IChannel>;

export const messageReducer = messageSlice.reducer;

type useStateType = (initialState: ItemState<IChannel> | (() => ItemState<IChannel>)) => [ItemState<IChannel>, (value: ItemState<IChannel>) => void];
// only seems to work in NextJs. Not in SPA.
export function useMessages(useStateHook: useStateType): [ItemState<IChannel>, (type: string, payload: IChannel[] | Partial<IChannel> | string) => void] {
  const [state, setState] = useStateHook(feature.initialState);
  const fn = (type: string, payload: IChannel[] | Partial<IChannel> | string): void => {
    const copy = extend({}, state);
    feature.reducers[type](copy, { type, payload });
    setState(copy);
  };
  return [state, fn];
}

const DAY = 1000 * 60 * 60 * 24;
const WEEK = DAY * 7;
export function getWebChatDateFormat(date: number) {
  const now = Date.now();
  const time = now - date;
  if (time > WEEK) {
    return formatDate(date, 'n/j/y h:ia');
  }
  if (time > DAY) {
    return formatDate(date, 'D h:ia');
  }
  return toTimeAgoShort(date, 'ago');
}
