import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import sortBy, { sortByAll } from '@cybus/helps/dist/helpers/sortBy';
import { Address } from '@cybus/helps/dist/types/Address';
import { Hash } from '@cybus/helps/dist/types/Hash';
import extend from '@cybus/helps/dist/helpers/extend';
import createReduxListFeature from '@cybus/helps/dist/helpers/createReduxListFeature';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

export interface IPhone {
  phone: string;
  type?: string;
  primary: boolean;
}
export interface ICustomer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  emails?: string[];
  phones: IPhone[];
  addresses: Address[];
  createdAt: number;
  createdBy: string;
  note?: string;
  referrer?: string;
}

export const getDefaultCustomer = (): ICustomer => {
  return {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phones: [{ phone: '', type: 'mobile', primary: true }],
    addresses: [{ street: '', city: '', state: '', zip: '' }],
    createdAt: 0,
    createdBy: '',
  };
};

const sort = sortBy('createdAt', false);//sortByAll(['lastName', 'firstName']);
const feature = createReduxListFeature('customer', getDefaultCustomer, sort);
feature.reducers.addCustomerPhone = (state: any, action: PayloadAction<string>) => {
  const id = action.payload as string;
  const customer = extend({}, state.hash[id]);
  customer.phones.push({ phone: '', type: 'mobile', primary: false });
  state.value = customer;
  state.hash[id] = customer;
  feature.update(state);
};
feature.reducers.addCustomerAddress = (state: any, action: PayloadAction<string>) => {
  const id = action.payload as string;
  const customer = extend({}, state.hash[id]);
  customer.addresses.push({ street: '', city: '', state: '', zip: '' });
  state.value = customer;
  state.hash[id] = customer;
  feature.update(state);
};
export const customerSlice = createSlice(feature);

export const {
  setValue: setCustomer,
  setList: setCustomers,
  update: updateCustomer,
  remove: removeCustomer,
  addCustomerPhone,
  addCustomerAddress,
} = customerSlice.actions;

export const getCustomer = (state: ToolkitStore | any) => state.customer.value as ICustomer;
export const getCustomers = (state: ToolkitStore | any) => state.customer.list as ICustomer[];
export const getCustomerById = (state: ToolkitStore | any) => state.customer.hash as Hash<ICustomer>;

export const customerReducer = customerSlice.reducer;
