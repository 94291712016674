import React, { ReactElement, ReactNode, createElement, useContext } from 'react';
import MultiRoute, { IMRoute } from '@cybus/helps/dist/helpers/MultiRoute';
import { createContext, useState } from 'react';
import { IHashData } from '@cybus/helps/dist/types/Hash';

export const MRouterContext = createContext(MultiRoute.state[0]);

export function useNavigate() {
  return (path: string, index: number = 0) => {
    MultiRoute.go(index, path);
  };
}

export interface ILocation {
  pathname: string;
  href: string;
  search: string;
}
export function useLocation(): ILocation {
  return document.location;
}

export function useRoute(): IMRoute[] {
  return useContext(MRouterContext);
}
export function useParams(): IHashData {
  return useRoute()?.[0]?.params || {};
}

export type RelativeRoutingType = 'route' | 'path';
export type To = string;
export interface LinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  reloadDocument?: boolean;
  replace?: boolean;
  state?: any;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
  to: string;
  index?: number;
}
export function Link(props: LinkProps): ReactElement<HTMLAnchorElement> {
  return (
    <a
      href={props.to}
      onClick={(e) => {
        e.preventDefault();
        MultiRoute.go(props.index, props.to);
      }}
    >
      {props.children}
    </a>
  );
}

export default function MRouter({ index }: { index: number }) {
  const [state, setState] = useState(MultiRoute.state[index] || ([{ path: '', element: <></> }] as IMRoute[]));
  MultiRoute.setDispatch(index, setState);
  return (
    <MRouterContext.Provider value={state}>
      {state.map((item: IMRoute): ReactNode => {
        if (item?.element && typeof item.element !== 'undefined') {
          return createElement(() => item.element || item.fallback || <></>, { key: `mr-${index}` });
        }
        return '';
      })}
    </MRouterContext.Provider>
  );
}
