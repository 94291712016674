import { TSiteConfig } from './../../types/TSiteConfig';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getDomainKey from '../../helpers/getDomainKey';
import { IAccount } from './IAccount';
import extend from '@cybus/helps/dist/helpers/extend';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

interface AccountState {
  value: IAccount;
  title: string;
}

export class AccountConfig {
  static domain:string;
}

const getDefaultAccount = (): IAccount => {
  return {
    id: getDomainKey(AccountConfig.domain),
    company: '',
    email: '',
    phone: '0000000000',
    locations: [{ street: '', city: '', state: '', zip: '' }],
    website: '',
  };
};

const initialState: AccountState = {
  value: getDefaultAccount(),
  title: '',
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<IAccount>) => {
      state.value = extend(getDefaultAccount(), action.payload);
    },
    setTitle: (state, action: PayloadAction<string>) => {
      if (state.title !== action.payload) {
        state.title = action.payload;
        document.title = `${state.value.company} ${action.payload}`;
      }
    },
  },
});

export const { setAccount, setTitle } = accountSlice.actions;

export const getAccount = (state: ToolkitStore | any) => state.account.value as IAccount;

export const accountReducer = accountSlice.reducer;
