import createReduxListFeature from "@cybus/helps/dist/helpers/createReduxListFeature";
import { createSlice } from "@reduxjs/toolkit";

export interface INote {
  id: string;
  customerId: string;
  userId: string;
  text: string;
  createdAt: number;
}

export interface INoteGroup {
  id: string; // customerId. used as id so it works with the slice model.
  notes: INote[];
}

export const getDefaultNote = (): INote => {
  return {
    id: '',
    customerId: '',
    userId: '',
    text: '',
    createdAt: 0,
  };
};

export const getDefaultNoteGroup = (): INoteGroup => {
  return {
    id: '',
    notes: [],
  };
};

// const sort = (a:INote, b:INote) => b.createdAt - a.createdAt; //sortBy('createdAt');
const key = 'notes';
export const noteSlice = createSlice(createReduxListFeature(key, getDefaultNoteGroup));

export const {
  setValue: setNote,
  setList: setNotes,
  update: updateNote,
  remove: removeNote,
} = noteSlice.actions;

export const getNote = (state: any) => state[key].value;
export const getNotes = (state: any) => state[key].list;
export const getNoteById = (state: any) => state[key].hash;

export const notesReducer = noteSlice.reducer;
