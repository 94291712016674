import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

interface DrawerState {
  value: { open: boolean };
}

const initialState: DrawerState = {
  value: { open: false },
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.value.open = action.payload;
    },
  },
});

export const { setDrawerOpen } = drawerSlice.actions;

export const getDrawerOpen = (state: ToolkitStore | any) => state.drawer.value.open as { open: boolean };

export const drawerReducer = drawerSlice.reducer;
