import FBService from '@cybus/helps/dist/helpers/FBService';
import { IPermission } from './IPerm';

const service = new FBService<IPermission>();
export const fetchPermission = (uid: string): Promise<IPermission> => {
  return service.fetch(`permissions`, uid);
};
export const fetchPermissions = (): Promise<IPermission[]> => {
  return service.fetchAll(`permissions`);
};

export const savePermission = (permission: IPermission): Promise<IPermission> => {
  return service.save(`permissions`, permission);
};
