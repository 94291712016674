import { Hash } from '@cybus/helps/dist/types/Hash';

export enum CRUD {
  NONE = 0,
  READ = 1,
  CREATE = 2,
  UPDATE = 3,
  DELETE = 4,
}
export interface IPerm {
  id: string;
  login: CRUD;
  users: CRUD;
  permissions: CRUD;
  customers: CRUD;
  dev: CRUD;
}

export type IPermission = IPerm & Hash<CRUD>;

export interface IPermGrant {
  key: keyof IPermission;
  value: CRUD;
  fallback?: string;
}
