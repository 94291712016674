export default function getDomainKey(domain:string): string {
  try {
    domain = domain ?? process.env.NEXT_PUBLIC_DOMAIN as string;;
    return domain;
  } catch (e) {
    if (globalThis.document) {
      const loc = document.location;
      return loc.hostname + (loc.port ? ':' + loc.port : '');
    }
    return '';
  }
}
