import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

interface LoadingState {
  value: { large: boolean; small: boolean };
}

const initialState: LoadingState = {
  value: { large: false, small: false },
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean[]>) => {
      state.value.large = action.payload[0];
      state.value.small = action.payload[1];
    },
  },
});

export const { setLoading } = loadingSlice.actions;

export const getLoading = (state: ToolkitStore | any) => state.loading.value as { large: boolean; small: boolean };

export const loadingReducer = loadingSlice.reducer;
