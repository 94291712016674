import { permissionReducer } from '../reactlib/features/permissions';
import { userReducer } from '../reactlib/features/users';
import { accountReducer } from '../reactlib/features/account';
import { configureStore } from '@reduxjs/toolkit';
import { loadingReducer } from '../reactlib/features/loading';
import { drawerReducer } from '../reactlib/features/drawer';
import { appointmentReducer } from '../reactlib/features/appointments';
import { customerReducer } from '../reactlib/features/customer';
import { toastsReducer } from '../reactlib/features/toast';
import { dialogReducer } from '../reactlib/features/dialog';
import { leadReducer } from '../reactlib/features/leads';
import { messageReducer } from '../reactlib/features/webchat';
import { notesReducer } from '../reactlib/features/notes';

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    toasts: toastsReducer,
    drawer: drawerReducer,
    account: accountReducer,
    permission: permissionReducer,
    user: userReducer,
    appointment: appointmentReducer,
    lead: leadReducer,
    customer: customerReducer,
    dialogs: dialogReducer,
    messages: messageReducer,
    notes: notesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
