const calendarColors = {
  today: 'bg-blue-100 text-blue-900',
  past: 'bg-gray-50 text-gray-400',
  selected: 'bg-primary text-primary-content',
  curMonth: 'bg-white text-neutral text-gray-400',
  nonCurMonth: 'bg-gray-100 text-gray-300',
  events: {
    appt: 'bg-secondary text-secondary-content',
    contract: 'bg-neutral text-neutral-content',
  },
};

export default calendarColors