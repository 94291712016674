import { createSlice } from '@reduxjs/toolkit';
import { IUser } from './IUser';
import { sortByAll } from '@cybus/helps/dist/helpers/sortBy';
import createReduxListFeature from '@cybus/helps/dist/helpers/createReduxListFeature';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { Hash } from '@cybus/helps/dist/types/Hash';

const getDefaultUser = (): IUser => {
  return {
    id: '',
    firstName: '',
    lastName: '',
    addresses: [{ street: '', city: '', state: '', zip: '' }],
    createdAt: Date.now(),
    // inherited propertie,
    uid: '',
    displayName: '',
    email: '',
    phone: '',
    emailVerified: false,
    provider: '',
  };
};

const sort = sortByAll(['lastName', 'firstName'])

export const userSlice = createSlice(createReduxListFeature<IUser>('user', getDefaultUser, sort ))

export const { setValue:setUser, setList:setUsers, update:updateUser } = userSlice.actions;

export const getUser = (state: ToolkitStore | any) => state.user.value as IUser;
export const getUsers = (state: ToolkitStore | any) => state.user.list as IUser[];
export const getUsersById = (state: ToolkitStore | any) => state.user.hash as Hash<IUser>;

export const userReducer = userSlice.reducer;
