// @ts-nocheck
import { CRUD, IPermission } from '../reactlib/features/permissions/IPerm';
import { IUser } from '../reactlib/features/users/IUser';
import { TSiteConfig } from '../reactlib/types/TSiteConfig';

let initialized = false;
export default function OneSignlaSetup(user: IUser, permission: IPermission, config:TSiteConfig) {
  if (!initialized && document.location.hostname === config.onesignal.domain) {
    initialized = true;
    // limit 2 tags on free plan.
    // TODO: this needs to be triggered by a notification preferences in the admin.
    const tags = {
      leads: permission.leads > CRUD.CREATE ? 1 : 0,
      appts: permission.appointments > CRUD.CREATE ? 1 : 0,
    };
    const script = document.createElement('script');
    script.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
    script.defer = true;
    script.onload = () => {
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      OneSignalDeferred.push(function (OS) {
        OS.init({ appId: config.onesignal.appId })
          .then(() => OS.login(user.id)) // creates a user if they don't exist with external_id
          .then(() => {
            const u = OS.User._currentUser;
            u?.addTags(tags);
          }).catch(e => {
            if (globalThis.window && globalThis.document.location.href.indexOf('localhost') !== -1) {
              console.log(e);
            }
          });
      });
    };
    document.head.appendChild(script);
  }
}
