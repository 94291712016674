import React, { useEffect } from 'react';
import './index.css';
import { IToast, expireToasts, getToasts } from '.';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from './icons/CheckIcon';
import WarningIcon from './icons/WarningIcon';
import ErrorIcon from './icons/ErrorIcon';
import InfoIcon from './icons/InfoIcon';
import Row from '../../components/Row';
import GLog from '@cybus/helps/dist/helpers/GLog';

const gl = GLog.init('toast');
interface ToastProps {
  className?: string;
  itemClassName?: string;
}

const getIcon = (name: string) => {
  switch (name) {
    case 'success':
      return <CheckIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'error':
      return <ErrorIcon />;
    default:
      return <InfoIcon />;
  }
};

export default function Toast({ className, itemClassName }: ToastProps) {
  const toasts = useSelector(getToasts);
  const dispatch = useDispatch();
  useEffect(() => {
    if (toasts.length) {
      setTimeout(() => {
        dispatch(expireToasts());
      }, 500);
    }
  }, [toasts]);
  gl.log(`render ${toasts.length}`);
  return (
    <div className={`overflow-visible ${className || ''}`}>
      {toasts.map((toast: IToast, i: number) => (
        <div
          key={`toast-${i}`}
          className={`toast ${toast.expired ? 'close' : 'active'} alert ${toast.type} ${itemClassName || ''}`}
        >
          <Row className="justify-start gap-4 w-full text-ellipsis whitespace-nowrap overflow-hidden">
            <span>{getIcon(toast.type.split('-').pop() || '')}</span>
            <span>{toast.message}</span>
          </Row>
        </div>
      ))}
    </div>
  );
}
