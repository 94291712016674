'use client'
import React, { useEffect, useState } from 'react';
import Col from '../Col';
import { Hash } from '@cybus/helps/dist/types/Hash';
import './index.css';

interface SpinnerProps {
  loaded: boolean;
  color: string;
  bg: string;
}

const promises: Hash<Promise<boolean>> = {};
const processReady = (key: string): Promise<boolean> => {
  const promise =
    promises[key] ||
    (promises[key] = new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 500);
    }));
  return promise;
};

const Spinner = ({ loaded, color, bg }: SpinnerProps) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (loaded && !ready) {
      processReady('key')?.then(() => {
        setReady(true);
      });
    }
  }, [loaded, ready]);
  return (
    <Col
      className={`absolute z-50 top-0 left-0 right-0 bottom-0 justify-center items-center ${
        bg || 'bg-white'
      } transition-opacity duration-300 ${loaded ? 'opacity-0' : 'opacity-100'} ${
        loaded && ready ? 'hidden pointer-events-none' : ''
      }`}
    >
      {/* Implement NextJS and get rid of this */}
      <div className={`lds-ripple ${color}`}>
        <div></div>
        <div></div>
      </div>
    </Col>
  );
};

export default Spinner;
