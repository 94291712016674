import extend from '@cybus/helps/dist/helpers/extend';
import { ICustomer, getDefaultCustomer } from '.';
import FBService, { WhereProps } from '@cybus/helps/dist/helpers/FBService';

const service = new FBService<ICustomer>();
export const customerFilter:{wp?: WhereProps} = { wp: undefined};

export const fetchLead = (id: string): Promise<ICustomer> => {
  return service.fetch(`leads`, id);
};
export const fetchLeadByEmail = (email: string): Promise<ICustomer> => {
  return service.fetchAll(`leads`, ['email', '==', email]).then((list) => list[0]);
};
export const fetchLeads = (): Promise<ICustomer[]> => {
  return service.fetchAll(`leads`, customerFilter.wp);
};
export const subscribeLeads = (callback: (customers: ICustomer[]) => void): Promise<ICustomer[]> => {
  return service.subscribe('leads', customerFilter.wp, callback as any) as unknown as Promise<ICustomer[]>;
};
export const saveLead = (customer: ICustomer): Promise<ICustomer> => {
  const c = extend(getDefaultCustomer(), customer);
  if (c.id) {
    return service.save(`leads`, c);
  }
  return service.create(`leads`, c);
};

export const deleteLead = (lead: ICustomer): Promise<void> => {
  return service.remove('leads', lead.id);
};

export const clearLeads = () => {
  service.clearAll('leads', 5);
}

export const fetchCustomer = (uid: string): Promise<ICustomer> => {
  return service.fetch(`customers`, uid);
};
export const fetchCustomers = (): Promise<ICustomer[]> => {
  // if the filter is set to null. We are blocking unless we have a referrer so don't call the db.
  if (customerFilter.wp && customerFilter.wp[2] === 'null') {
    return Promise.resolve([]);
  }
  return service.fetchAll(`customers`, customerFilter.wp);
};
export const subscribeCustomers = (callback: (customers: ICustomer[]) => void): Promise<ICustomer[]> => {
  return service.subscribe('customers', customerFilter.wp, callback as any) as unknown as Promise<ICustomer[]>;
};
export const fetchCustomersByEmail = (email: string): Promise<ICustomer> => {
  return service.fetchAll(`customers`, ['email', '==', email]).then((list) => list[0]);
};

export const saveCustomer = (customer: ICustomer): Promise<ICustomer> => {
  const c = extend(getDefaultCustomer(), customer);
  if (c.id) {
    return service.save(`customers`, c);
  }
  return service.create(`customers`, c);
};

export const deleteCustomer = (customer: ICustomer): Promise<void> => {
  return service.remove('customers', customer.id);
};

export const clearCustomers = () => {
  service.clearAll('customers', 30);
}

